<template>
  <div class="viber-modal">
    <div class="viber-modal__container">
      <div class="viber-modal__header">
        <div class="viber-modal__close-btn" @click="$emit('close')">
          <svg class="icon close-icon">
            <use xlink:href="~@/assets/icons/close.svg#close"></use>
          </svg>
        </div>
      </div>
      <div class="viber-modal__body">
        <p class="viber-modal__description viber-description">
          Подпишись на
          <a
            class="viber-description__link"
            href="https://invite.viber.com/?g2=AQBWXQ7G5oJvyUuTAlm97dtV24411gpeao%2BhxunrTz2iae9WHciK0%2FSrka4y44IS&lang=en"
          >
            сообщество EasyBase
          </a>
          и получай призы 😉
        </p>
        <svg class="icon viber-popup-icon">
          <use xlink:href="~@/assets/icons/viber-popup.svg#viber-popup"></use>
        </svg>
      </div>
      <div class="viber-modal__footer"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToViberPopup'
};
</script>

<style lang="sass">

.viber-modal
  width: 100%
  height: 100vh
  max-height: 100vh
  overflow: hidden
  position: fixed
  background-color: #5D3550
  z-index: 2000
  top: 0
  transition: right .4s ease-in-out
  right: -100%
  text-align: center
  &.active-viber
    right: 0%
  &__container
    padding: 20px
  &__header
    margin-bottom: 30px
  &__close-btn
    display: block
    margin-left: auto
    width: 45px
    height: 45px

  .viber-description
    font-size: 24px
    margin-bottom: 40px
    &__link
      color: #12b9c3
      text-decoration: underline
  .active-viber
    right: 100%
</style>
